// import { Outlet, Navigate } from "react-router-dom";
// export default function ProtectedRoute() {
//   const token = localStorage.getItem("admin");
//   if (!token) {
//     return <Navigate to="/Login" replace />;
//   }
//   return <Outlet />;
// }


import { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";


export default function ProtectedRoute() {

  const baseURL = process.env.REACT_APP_API_URL;

  const [isAuthenticated, setIsAuthenticated] = useState(null); // null initially, so we can check token status
  const token = localStorage.getItem("admin");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await fetch(`${baseURL}/admin/token-veirfy`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        if (data.status) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    if (token) {
      verifyToken();
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or a spinner while verifying
  }

  if (!isAuthenticated) {
    return <Navigate to="/Login" replace />;
  }

  return <Outlet />;
}
